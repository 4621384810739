import EndpointLogsItemResponse from '../../../../../logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse';
import PageResponse from '../../../../../pagination-helper/lib/PageResponse';

export default class GetEndpointLogsResponse  {
	Items: Array<EndpointLogsItemResponse>;
	Page: PageResponse;
	constructor(obj?: Partial<GetEndpointLogsResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
