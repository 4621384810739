
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import FilterModel from "./components/filter-model";
import getUTC from "@/function/getUTC";
import GetEndpointLogsRequest from "@/dataSource/api/logger-micro-service/services/models/request/log-getter-service/GetEndpointLogsRequest";
import EndpointLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetEndpointLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetEndpointLogsResponse";
import GetActiveEndpointLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetActiveEndpointLogsResponse";

@Options({
  name: "LogsViewPage",
  components: {
    TableView,
  },
})
export default class LogsView extends Vue {
  // @Prop({ default: new FilterModel() }) filter: FilterModel;
  @Prop({ default: false }) isActive: boolean;

  innerFilter: GetEndpointLogsRequest;
  items: EndpointLogsItemResponse[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  created() {
    // if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
    //   this.skipPage = this.filter.SkipPage;
    // }
    this.getInLocalStor()
  }

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }
  filter: FilterModel = new FilterModel();
  //получить данные фильтра для страницы если они есть
  getInLocalStor() {
    if (localStorage.logsViewFilter) {
      this.filter = JSON.parse(localStorage.logsViewFilter);
      const t = 0// new Date().getTimezoneOffset();
      this.filter.DateFrom = this.addMinutes(this.filter.DateFrom, t);
      this.filter.DateTo = this.addMinutes(this.filter.DateTo, t);
      return
    }
    // this.saveInLocalStor()
  }
  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    // if (this.$route.name == "logs-view") {
    // console.log(`logs-view`, this.filter);
    this.refresh();
    // }
  }

  // методы логов
  async refresh() {
    this.getInLocalStor()
    if (!this.filter) {
      return;
    }
    // this.skipPage = this.filter.SkipPage;
    let response: GetEndpointLogsResponse | GetActiveEndpointLogsResponse =
      null;
    this.isLoading = true;

    this.innerFilter = {
      OnlyActiveRequests: this?.isActive,
      EndpointId: this.filter.EndpointId,
      LevelId: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId:
        this.filter.ProjectLogGroupId === -1
          ? null
          : this.filter.ProjectLogGroupId,
      Page: { Skip: this.skipPage, Take: this.takePage },
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
    };

    let tmpFilter = getUTC(this.innerFilter);

    try {
      if (this?.isActive) {
        
        response = await this.$api.LogGetterService.getActiveEndpointLogsAsync(
          tmpFilter
        );
      } else {
        
        response = await this.$api.LogGetterService.getEndpointLogsAsync(
          tmpFilter
        );
      }
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      console.log(`error:`, error);
      response = new GetEndpointLogsResponse();
      response.Items=[]
      response.Page = new PageResponse()
      this.isLoading = false;
    }
    this.isLoading = false;

    this.pageResponse = response.Page;

    this.items = (response.Items as any).map((item) => ({
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      EndpointId: item.EndpointId,
      Class: item.Class,
      Method: item.Method,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
      ProjectName: item.ProjectName,
      ProjectLogGroupId: item.ProjectLogGroupId,
      ServerName: item.ServerName,
      InstanceName: item.InstanceName,
    }));

    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage =
        this.skipPage / this.takePage + 1;
    }
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
        let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    // if (this.skipPage === skip) {
    //   return;
    // }

    this.skipPage = skip;
    this.updateQuery();

    this.refresh();

  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  updateQuery() {
    this.$emit("updateQuery", this.skipPage);
  }
}
